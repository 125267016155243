// COMPONENTS
import React from 'react'
import LandingPage from 'templates/LandingPage'
// CONTENT
import content from 'content/index.yml'
import layout from 'content/layout.yml'

// Home page
const HomePage = () => {
  return <LandingPage content={{ ...layout, ...content }} />
}

export default HomePage
